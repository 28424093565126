@media (max-width: 600px){
    .leftCn{
        margin-top: 10px !important;
    }
}
/* HomeR.css */

.hakx {
    text-shadow: 0 0 12px rgba(0, 123, 255, 0.7);
                 
                 
                 
  }
  
@media (max-width: 1010px){
    .hakx{
        font-size: 62px !important;
    }

    .inter{
        font-size: 18px !important;
    }
}

@media (max-width: 389px){
    .hakx{
        font-size: 52px !important;
    }

    .inter{
        font-size: 17px !important;
    }
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');


/* .leftCn{
    top:400px !important;
} */

.leftCn, .rightCn {
    padding-left: 0; 
    padding-right: 0; 
  }
/* 
  @media (min-width: 1100px){
    img{
        left: -40%;

    }
  }   */